import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";

import {
Box,
Button,
Checkbox,
Flex,
FormControl,
FormLabel,
Heading,
Icon,
Input,
InputGroup,
InputRightElement,
Text,
useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import Swal from 'sweetalert2';

function SignIn() {
	 
const textColor = useColorModeValue("navy.700", "white");
const textColorSecondary = "gray.400";
const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
const textColorBrand = useColorModeValue("brand.500", "white");
const brandStars = useColorModeValue("brand.500", "brand.400");
const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
const googleText = useColorModeValue("navy.700", "white");
const BASE_URL = process.env.REACT_APP_BASE_URL;
const googleHover = useColorModeValue(
{ bg: "gray.200" },
{ bg: "whiteAlpha.300" }
);
const googleActive = useColorModeValue(
{ bg: "secondaryGray.300" },
{ bg: "whiteAlpha.200" }
);
const [show, setShow] = useState(false);
const handleClick = () => setShow(!show);
 const [marqueeData, setMarqueeData] = useState([]);

const [isLoading, setIsLoading] = useState(false);
const [formData, setFormData] = useState({
email: "",
password: "",
});
const [loginMessage, setLoginMessage] = useState(""); // State to store the login message
const history = useHistory();

const handleInputChange = (event) => {
const { name, value } = event.target;
setFormData((prevData) => ({
...prevData,
[name]: value,
}));
};



 useEffect(() => {
// Fetch data from the provided URL
const fetchData = async () => {
try {
const response = await fetch(`${BASE_URL}/pricing`);
const data = await response.json();
setMarqueeData(data.data_plan);
} catch (error) {
console.error("Error fetching data:", error);
}
};

fetchData(); // Call the fetchData function when the component mounts
}, []);



const handleFingerprintAuth = async () => {
try {
if (window.PublicKeyCredential) {
const credential = await navigator.credentials.get({
publicKey: {
challenge: new Uint8Array(16),
allowCredentials: [], 
userVerification: "required", 
},
});

if (credential) {
const credentialId = credential.id;

localStorage.setItem("fingerprintPasskey", credential.id);

const response = await axios.post(`${BASE_URL}/api/biometric_login`, {
credentialId: credentialId,
});

if (response.data.isAuthenticated) {
setAuthorizationCookie(response.data.authorization);
history.push("/dashboard");
} else {
setLoginMessage("Fingerprint authentication failed");
}
} else {
setLoginMessage("Fingerprint authentication failed");
}
} else {
setLoginMessage("Fingerprint authentication is not supported in this browser");
}
} catch (error) {
console.error("Error during fingerprint authentication:", error.message);
setLoginMessage("An error occurred during fingerprint authentication");
}
};

















const handleSignIn = async (event) => {
event.preventDefault();
setIsLoading(true);

try {
const sanitizedEmail = formData.email.trim();
const sanitizedPassword = formData.password.trim();

const response = await axios.post(`${BASE_URL}/api/auth`, {
email: sanitizedEmail,
password: sanitizedPassword,
});

if (response.data.isAuthenticated) {
setAuthorizationCookie(response.data.authorization);
history.push("/dashboard");
} else {
Swal.fire({
icon: 'error',
title: 'Login Failed',
text: `${response.data.message}`,
confirmButtonColor: '#d33',
confirmButtonText: 'OK',
});
}
} catch (error) {
console.error("Error during login:", error.message);
Swal.fire({
icon: 'error',
title: 'Error',
text: 'An error occurred. Please try again later.',
confirmButtonColor: '#d33',
confirmButtonText: 'OK',
});
}

setIsLoading(false);
};

function setAuthorizationCookie(authorization) {
const cookieName = 'authorization';
const expirationDate = new Date();
expirationDate.setDate(expirationDate.getDate() + 7);
document.cookie = `${cookieName}=${authorization}; expires=${expirationDate.toUTCString()}; path=/; secure; SameSite=Strict`;
}

return (


<DefaultAuth illustrationBackground={illustration} image={illustration}>
<Flex
maxW={{ base: "100%", md: "max-content" }}
w="100%"
mx={{ base: "auto", lg: "0px" }}
me="auto"
h="100%"
alignItems="start"
justifyContent="center"
mb={{ base: "30px", md: "60px" }}
px={{ base: "25px", md: "0px" }}
mt={{ base: "40px", md: "14vh" }}
flexDirection="column"
>
<Box me="auto">

 
 <marquee behavior="scroll" direction="left" scrollamount="11">
<Heading color="blue" fontSize="22px" mb="1px">
{marqueeData.map((plan) => (
<span key={plan.id}>
{`${plan.plan_type.replace(/_/g, ' ')} ${plan.data_size}${plan.size_type}  = ₦${plan.reseller_amnt}`} &nbsp; - &nbsp;&nbsp;
</span>
))}
<br />
<br />
</Heading>
</marquee>


<Heading color={textColor} fontSize="36px" mb="10px">
Sign In
</Heading>
<Text
mb="36px"
ms="4px"
color={textColorSecondary}
fontWeight="400"
fontSize="md"
>
Enter your email and password to sign in!
</Text>
</Box>
<Flex
zIndex="2"
direction="column"
w={{ base: "100%", md: "420px" }}
maxW="100%"
background="transparent"
borderRadius="15px"
mx={{ base: "auto", lg: "unset" }}
me="auto"
mb={{ base: "20px", md: "auto" }}
>
<FormControl>
<FormLabel
display="flex"
ms="4px"
fontSize="sm"
fontWeight="500"
color={textColor}
mb="8px"
>
Email<Text color={brandStars}>*</Text>
</FormLabel>
<Input
isRequired={true}
variant="auth"
fontSize="sm"
ms={{ base: "0px", md: "0px" }}
type="email"
placeholder="user@email.com"
mb="24px"
fontWeight="500"
size="lg"
name="email"
value={formData.email}
onChange={handleInputChange}
/>
<FormLabel
ms="4px"
fontSize="sm"
fontWeight="500"
color={textColor}
display="flex"
>
Password<Text color={brandStars}>*</Text>
</FormLabel>
<InputGroup size="md">
<Input
isRequired={true}
fontSize="sm"
placeholder="Password"
mb="24px"
size="lg"
type={show ? "text" : "password"}
variant="auth"
name="password"
value={formData.password}
onChange={handleInputChange}
/>
<InputRightElement display="flex" alignItems="center" mt="4px">
<Icon
color={textColorSecondary}
_hover={{ cursor: "pointer" }}
as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
onClick={handleClick}
/>
</InputRightElement>
</InputGroup>
<Flex justifyContent="space-between" align="center" mb="24px">
<FormControl display="flex" alignItems="center">
<Checkbox
id="remember-login"
colorScheme="brandScheme"
me="10px"
/>
<FormLabel
htmlFor="remember-login"
mb="0"
fontWeight="normal"
color={textColor}
fontSize="sm"
>
Keep me logged in
</FormLabel>
</FormControl>
<NavLink to="/auth/forgot-password">
<Text
color={textColorBrand}
fontSize="sm"
w="124px"
fontWeight="500"
>
Forgot password?
</Text>
</NavLink>
</Flex>
<Button
isLoading={isLoading}
fontSize="sm"
variant="brand"
fontWeight="500"
w="100%"
h="50"
mb="24px"
onClick={handleSignIn}
>
Sign In
</Button>
</FormControl>
<Flex
flexDirection="column"
justifyContent="center"
alignItems="start"
maxW="100%"
mt="0px"
>

{loginMessage && (
<Text color={loginMessage.includes("successful") ? "green.500" : "red.500"} fontWeight="500" fontSize="14px">
{loginMessage}
</Text>
)}

<Text color={textColorDetails} fontWeight="400" fontSize="14px">
Not registered yet?
<NavLink to="/auth/sign-up">
<Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
Create an Account
</Text>
</NavLink>


</Text>
</Flex>
</Flex>
</Flex>
</DefaultAuth>
);
}

export default SignIn;
