import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import "./SignIn.css";

function SignIn() {
  useEffect(() => {
 
  }, []);

  const handleAndroidDownload = () => {
    window.location.href = "https://app.cart9.com.ng/cart9-data-hub.apk";
  };

  return (
    <div className="signIn-container">
      <div className="content">
        <h1 className="app-title">Cart9 Data Hub App</h1>
        <div className="instructions"><br />
          <div className="android-instructions">
            <h2><b>ANDROID INSTRUCTIONS</b></h2>
            <ol className="instruction-list">
              <li>Click the "Download Android App" button below to download the APK file.</li>
              <li>Once downloaded, open the APK file.</li>
              <li>If prompted, enable the option to install from "Unknown Sources" in your device settings.</li>
              <li>Follow the on-screen instructions to complete the installation.</li>
            </ol>
        <div className="whatsapp-button">
          <a href="#" onClick={handleAndroidDownload} className="download-button">
            Download Android App
          </a>
        </div>
          </div>
<br /><hr /><br />
          <div className="ios-instructions">
            <h2><b>IOS INSTRUCTIONS</b></h2>
            <ol className="instruction-list">
              <li>Open Chrome or any browser on your iOS device.</li>
              <li>Visit <a href="https://app.cart9.com.ng/" target="_blank" rel="noopener noreferrer">cart9.com.ng</a></li>
              <li>Click the "Share" icon in Chrome.</li>
              <li>Select "Add to Home Screen" from the options.</li>
              <li>Follow the on-screen instructions to create a shortcut on your home screen for the Cart9 Data Hub App.</li>
            </ol>
          </div>
        </div>

      </div>
    </div>
  );
}

export default SignIn;
