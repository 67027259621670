import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, useColorModeValue, Input, Button, Flex, Text, VStack, Divider, Badge } from '@chakra-ui/react';
import Cookies from 'js-cookie';

export default function Notifications() {
  const boxBg = useColorModeValue('white', 'secondaryGray.300');
  const history = useHistory();

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [notifications, setNotifications] = useState([]); // Rename "histories" to "notifications"
  const jwtToken = Cookies.get('authorization');
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
if (jwtToken) {
  setIsUserLoggedIn(true);
  fetchNotifications(); // Rename "fetchUserData" to "fetchNotifications"
} else {
  setIsUserLoggedIn(false);
  history.push('/auth/sign-in');
}
  }, [history, jwtToken]);

  const fetchNotifications = async () => { // Rename "fetchUserData" to "fetchNotifications"
try {
  const response = await fetch(`${BASE_URL}/api/messages`, { // Change the API endpoint to "/api/messages"
method: 'POST',
headers: {
  Authorization: jwtToken,
},
  });

  if (!response.ok) {
const errorResponse = await response.json();
return;
  }

  const data = await response.json();
  setNotifications(data.notifications); // Rename "setHistories" to "setNotifications"
} catch (error) {
  console.error('Error fetching notifications:', error); // Rename the error message
}
  };

  return (
<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
  <Box
bg={boxBg}
p="20px"
borderRadius="lg"
shadow="base"
width={{ base: '100%', md: '100%', xl: '100%' }}
mx={{ base: '0', md: 'auto' }}
mb="20px"
  >
  
<VStack align="stretch" spacing="4">
  {notifications.map((notification, index) => (
<Box key={index} p="4" borderRadius="md"  borderWidth="1px" borderColor="gray.200">
<Text><Badge colorScheme={notification.opened === '0' ? 'green' : 'gray'}>
{notification.opened === '0' ? 'New' : ''}
  </Badge> <b>{notification.title}</b></Text><br />
<Text>{notification.message}</Text>
  <Text><small>{notification.date}</small></Text> 
  <Text>{notification.recipient}</Text>
  
  {/* ... (other notification details) */}
</Box>
  ))}
</VStack>
  </Box>
</Box>
  );
}
