/*eslint-disable*/
import React from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
const API_URL = process.env.REACT_APP_API_URL;
export default function Footer() {
  let textColor = useColorModeValue("gray.400", "white");
  let linkColor = useColorModeValue({ base: "gray.400", lg: "white" }, "white");
  return (
    <Flex
      zIndex='3'
      flexDirection={{
        base: "column",
        lg: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent='space-between'
      px={{ base: "30px", md: "0px" }}
      pb='30px'>
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", lg: "0px" }}>
        {" "}
        &copy; {1900 + new Date().getYear()}
        <Text as='span' fontWeight='500' ms='4px'>
       All Rights Reserved. Made by
          <Link
            mx='3px'
            color={textColor}
            href='https://www.fb.com/sile.love1'
            target='_blank'
            fontWeight='700'>
            OgaPositive
          </Link> |   
                   <Link fontWeight='500' color={textColor} href={API_URL}>
  API Documentation
</Link>
        </Text>
      </Text>
    </Flex>
  );
}
