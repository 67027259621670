import React from "react";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
  const SITE_EMAIL = process.env.REACT_APP_SITE_EMAIL;
  const SITE_WHATSAPP = process.env.REACT_APP_SITE_WHATSAPP;
  const SITE_PHONE = process.env.REACT_APP_SITE_PHONE;
  const SITE_ADDRESS = process.env.REACT_APP_SITE_ADDRESS;
function SignIn() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Text color={textColor} fontSize="36px" mb="10px">
          Contact Us
        </Text>
        <Text
          mb="36px"
          ms="4px"
          color={textColorSecondary}
          fontWeight="400"
          fontSize="md"
        >
          For any inquiries or assistance, please contact us using the details below:
        </Text>
        <Flex flexDirection="column" justifyContent="start" alignItems="start">
          <Text color={textColor} fontWeight="500" fontSize="14px">
            Email: {SITE_EMAIL}
          </Text>
          <Text color={textColor} fontWeight="500" fontSize="14px">
            Phone: {SITE_PHONE}
          </Text>
          <Text color={textColor} fontWeight="500" fontSize="14px">
            WhatsApp ChatBot: {SITE_WHATSAPP}
          </Text>
          <Text color={textColor} fontWeight="500" fontSize="14px">
            Address: {SITE_ADDRESS}
          </Text>
        </Flex>
        <Text color={textColorBrand} fontWeight="500" fontSize="14px">
          For technical support, please email: {SITE_EMAIL}
        </Text>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
