import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Input,
  Button,
  Switch,
  useColorModeValue,
} from "@chakra-ui/react";
import Cookies from 'js-cookie';

export default function UserReports() {
  const boxBg = useColorModeValue("white", "secondaryGray.300");
  const history = useHistory();

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Add this line

  const [dataPlans, setDataPlans] = useState([]);
  const [selectedDataPlan, setSelectedDataPlan] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [transactionPin, setTransactionPin] = useState('');
  const [saveAs, setSaveAs] = useState('');
  const [networkName, setNetworkName] = useState('');
  const [bypassValidation, setBypassValidation] = useState(false);
  const authorizationToken = Cookies.get('authorization');
  const [userData, setUserData] = useState({});
  const [dataPlan, setDataPlan] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [fundingData, setFundingData] = useState([]);
  const jwtToken = Cookies.get('authorization');
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (jwtToken) {
      setIsUserLoggedIn(true);
    } else {
      setIsUserLoggedIn(false);
      history.push('/auth/sign-in');
    }
  }, [history]);
  const [isLoadingDataPlans, setIsLoadingDataPlans] = useState(false);

  useEffect(() => {
    const fetchDataPlans = async () => {
      try {
        if (!jwtToken) {
          return;
        }

        const fetchUserData = async () => {
          try {
            const response = await fetch(`${BASE_URL}/api/user`, {
              method: 'GET',
              headers: {
                Authorization: jwtToken,
              },
            });

            if (!response.ok) {
              const errorResponse = await response.json();
              return;
            }

            const data = await response.json();
            setUserData(data.userInformation);
            setFundingData(data.funding);
            setDataPlan(data.data_plan);
            setNetworks(data.networks);


          } catch (error) {
            console.error('Error fetching user data:', error);
          }
        };

        // Fetch user data
        fetchUserData();
      } catch (error) {
        console.error('Error fetching data plans:', error);
      }
    };

    fetchDataPlans();
  }, [selectedNetwork, authorizationToken]);

  useEffect(() => {
    const mappedDataPlans = dataPlan.map((plan) => ({
      ...plan,
      network: parseInt(plan.network),
    }));

    setDataPlans(mappedDataPlans);
  }, [dataPlan]);

  useEffect(() => {
    const checkPhoneNumberNetwork = () => {
      const networkPrefixes = {
        MTN: ['0703', '0706', '0803', '0806', '0810', '0813', '0814', '0816', '0903', '0906', '0913'],
        Glo: ['0705', '0805', '0807', '0811', '0815', '0905'],
        Airtel: ['0701', '0708', '0702', '0704', '0802', '0808', '0812', '0901', '0902', '0904', '0907', '0912'],
        '9mobile': ['0809', '0817', '0818', '0909', '0908'],
      };

      const numberPrefix = phoneNumber.substr(0, 4);
      let network = '';
      Object.entries(networkPrefixes).forEach(([networkName, prefixes]) => {
        if (prefixes.includes(numberPrefix)) {
          network = networkName;
        }
      });

      setNetworkName(network);
    };

    if (!bypassValidation) {
      checkPhoneNumberNetwork();
    } else {
      setNetworkName('');
    }
  }, [phoneNumber, bypassValidation]);






  const handleBuyNow = async () => {
    if (!selectedNetwork || !selectedDataPlan || !phoneNumber) {
      return;
    }

    try {
      if (!jwtToken) {
        throw new Error('Unauthorized');
      }
      setIsLoading(true);


	const mypin = userData.pin;
	
if (transactionPin !== mypin) {
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        text: 'Incorrect Transaction PIN. If you`ve forgotten your transaction PIN, please reach out to us for assistance.',
      }).then(() => {});
      return;
    }

      const formData = {
        network: selectedNetwork,
        dataPlan: selectedDataPlan,
        phoneNumber: phoneNumber,
        transactionPin: transactionPin,
        saveAs: saveAs,
        bypassValidation: bypassValidation,
      };

      const response = await axios.post(`${BASE_URL}/api/buy_data`, formData, {
        headers: {
          Authorization: jwtToken,
        },
      });
      setIsLoading(false);

      const successMessage = response.data.message;
      Swal.fire({
      icon: 'success',
      text: successMessage,
    }).then(() => {
      // Reload the page
      window.location.reload();
    });

      setSelectedNetwork('');
      setSelectedDataPlan('');
      setPhoneNumber('');
      setTransactionPin('');
      setSaveAs('');
      setBypassValidation('');
    } catch (error) {
      console.error('Error making API request:', error.response ? error.response.data.error : 'An error occurred while making the API request.');
      Swal.fire({
        icon: 'error',
        text: error.response ? error.response.data.error : 'An error occurred while making the API request.',
      });
	        setIsLoading(false);

    }
  };








  const handleNetworkChange = async (newNetwork) => {
    setIsLoadingDataPlans(true);
    setSelectedNetwork(newNetwork);
    setSaveAs('');

    try {
      const response = await axios.get(`${BASE_URL}/api/data_plans?network=${newNetwork}`, {
        headers: {
          Authorization: jwtToken,
        }, 
      });

      const newDataPlans = response.data.data_plans;
      setDataPlan(newDataPlans);
      setSelectedDataPlan('');

    } catch (error) {
      console.error('Error fetching data plans:', error);
    }
    setIsLoadingDataPlans(false);
  };

  const filteredDataPlans = dataPlans.filter((plan) => plan.network === parseInt(selectedNetwork));

  return (
    <Box pt={{ base: "50px", md: "50px", xl: "0px" }}>
      <Box
        bg={boxBg}
        p="20px"
        borderRadius="lg"
        shadow="base"
        width={{ base: "100%", md: "45%", xl: "45%" }}
        mx={{ base: "0", md: "auto" }}
        mb="20px"
      >
        <Flex justify="space-between" align="center" mb="20px">
          <Heading as="h3" size="lg">
            Buy Data
          </Heading>
        </Flex>
        <form>
          <FormControl id="network" mb="4">
            <FormLabel>Network</FormLabel>
            <Select
              placeholder="Select network"
              value={selectedNetwork}
              onChange={(e) => handleNetworkChange(e.target.value)}
            >
              {networks.map((network) => (
                <option key={network.network_code} value={network.network_code}>
                  {network.network}
                </option>
              ))}
            </Select>
</FormControl>

          <FormControl id="dataPlan" mb="4">
            <FormLabel>Data Plan</FormLabel>
<Select
  placeholder="Select data plan"
  value={selectedDataPlan}
  onChange={(e) => setSelectedDataPlan(e.target.value)}
  disabled={!selectedNetwork}
>
  {filteredDataPlans.map((plan) => (
    <option key={plan.plan_id} value={plan.plan_id}>
      {plan.data_size} {plan.plan_type.replace(/_/g, ' ')} = ₦{plan.amount} - {plan.validity}
    </option>
  ))}
</Select>

          </FormControl>

          <FormControl id="phoneNumber" mb="4">
            <FormLabel>Phone Number</FormLabel>
            <Input type="number" placeholder="Enter phone number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="bypassValidation" mb="0">
              Bypass Number Validation
            </FormLabel>
            <Switch id="bypassValidation" ml="2" isChecked={bypassValidation} onChange={(e) => setBypassValidation(e.target.checked)} />
          </FormControl>

          {networkName && !bypassValidation && (
            <Box my="2" color="green">
              <b>{networkName}</b> number detected.
            </Box>
          )}

          <FormControl id="saveAs" mb="4">
 
  <FormLabel>Save Contact As (optional)</FormLabel>
  <Input 
    autoComplete="off" 
    type="text" 
    placeholder="Mr Positive"
    value={saveAs} 
    onChange={(e) => setSaveAs(e.target.value)} 
    onPaste={(e) => e.preventDefault()} 
    onInput={(e) => e.preventDefault()} 
  />
</FormControl>


          <FormControl id="transactionPin" mb="4">
            <FormLabel>Transaction Pin</FormLabel>
            <Input type="password" placeholder="****" value={transactionPin} onChange={(e) => setTransactionPin(e.target.value)} />
          </FormControl>

 <Button colorScheme="blue" mt="4" onClick={handleBuyNow} isLoading={isLoading} loadingText="Placing Order" disabled={isLoading}>
          Place Order
        </Button>
        </form>
      </Box>
	  
	   <Box
        bg={boxBg}
        p="20px"
        borderRadius="lg"
        shadow="base"
        width={{ base: "100%", md: "45%", xl: "45%" }}
        mx={{ base: "0", md: "auto" }}
        mb="20px"
      >
	  <b>Balance Check</b><br /><br />
	 To check data balance on MTN, GLO, AIRTEL and 9MOBILE, simply dial <b>*323#</b> and follow the prompt.
      </Box>
	  
	  
	  
  
    </Box>
  );
}
