import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
  const BASE_URL = process.env.REACT_APP_BASE_URL;
function clearAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
  }
}

export default function Logout() {
  // Use useHistory hook to access the history object
  const history = useHistory();

  // Function to handle the logout process
  async function handleLogout() {
    try {
      // Trigger backend logout
      const response = await fetch(`${BASE_URL}/api/logout`, {
        method: 'GET',
      });

      if (response.ok) {
        // Clear all cookies
        clearAllCookies();
        history.push('/auth/sign-in');
      }
    } catch (error) {
      console.error('Error logging out:', error);
      // Handle error if needed
    }
  }

  useEffect(() => {
    // Call handleLogout when the component mounts
    handleLogout();
  }, []);

  return (
    <div>
      <p>Logging out...</p>
    </div>
  );
}
