import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Input,
  Button,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import Modal from 'react-modal';
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useReactToPrint } from 'react-to-print';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    padding: 10,
  },
  section: {
    flexGrow: 1,
  },
});

const authorizationToken = Cookies.get('authorization');

export default function UserReports() {
  const boxBg = useColorModeValue("white", "secondaryGray.300");
  const history = useHistory();

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [airtimeAmount, setAirtimeAmount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [networkName, setNetworkName] = useState('');
  const [bypassValidation, setBypassValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataPlans, setDataPlans] = useState([]);
  const [responseText, setResponseText] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [previousData, setPreviousData] = useState([]);
  const [lightStatus, setLightStatus] = useState('');
  const jwtToken = Cookies.get('authorization');
  const BASE_URL = process.env.REACT_APP_BASE_URL;

 
 
  const pdfRef = useRef();

  const handlePrintPDF = useReactToPrint({
    content: () => pdfRef.current,
  });

  const handleDownloadPDF = () => {
    const blob = new Blob([responseText], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'response.pdf';
    a.click();
  };


const PDFDocument = () => (
<Document>
<Page size="A4" style={styles.page}>
<View style={styles.section}>
<Text>{responseText}</Text>
</View>
</Page>
</Document>
  );

return (
<Box pt={{ base: "130px", md: "80px", xl: "80px" }}>


<Box
bg={boxBg}
p="20px"
borderRadius="lg"
shadow="base"
width={{ base: "100%", md: "45%", xl: "45%" }}
mx={{ base: "0", md: "auto" }}
mb="20px"
      >
<Flex justify="space-between" align="center" mb="20px">
<Heading as="h3" size="lg">
Flyer Generator
</Heading>
</Flex>
Coming Soon
</Box>




<Box
bg={boxBg}
p="20px"
borderRadius="lg"
shadow="base"
width={{ base: "100%", md: "45%", xl: "45%" }}
mx={{ base: "0", md: "auto" }}
mb="20px">
<Flex justify="space-between" align="center" mb="20px">
<Heading as="h3" size="lg">
Affiliate
</Heading>
</Flex>
Coming Soon
</Box>



</Box>
  );
}
