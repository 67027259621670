import React from "react";
import { Flex, useColorModeValue } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      {/* Replace the text with the logo image */}
      <img src="/nav_logo.png" alt="Logo" style={{ width: "100px", height: "auto" }} />
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
