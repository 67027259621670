import React, { useState, useEffect } from 'react';
import useAuthentication from '../../../utils/auth';
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Text,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import Mtn from "assets/img/dashboards/mtn.png";
import Airtel from "assets/img/dashboards/airtel.png";
import Etisalat from "assets/img/dashboards/etisalat.png";
import GLo from "assets/img/dashboards/glo.png";
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";

import {
  MdBalance,
  MdBarChart,
  MdVerifiedUser,
  MdContentCopy,
  MdWifi,
  MdPower,
  MdInfo,
  MdOutlineVerifiedUser,
  MdGroup,
} from "react-icons/md";
import Tasks from "views/admin/default/components/Tasks";
import Tasks2 from "views/admin/default/components/Tasks2";
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  
const REF_PERC = process.env.REACT_APP_REFER_BONUS;
	
    
const SITE_URL = process.env.REACT_APP_MAIN_SITE_URL;
	
      
const WhatsApp = process.env.REACT_APP_SITE_WHATSAPP;
	
      
const WhatsApp_Group = process.env.REACT_APP_SITE_WHATSAPP_GROUP;
	
  
export default function UserReports() {

const [selectedUpgrade, setSelectedUpgrade] = useState(""); 
  const [advice, setAdvice] = useState('');



 useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.adviceslip.com/advice');
        const responseData = await response.json();

        if (responseData && responseData.slip && responseData.slip.advice) {
          setAdvice(responseData.slip.advice);
        }
      } catch (error) {
        console.error('Error fetching advice:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once after the initial render



const handleUpgradeSubmit = async () => {
  if (selectedUpgrade) {
    try {
      const response = await fetch(`${BASE_URL}/api/upgrade`, {
        method: 'POST',
        headers: {
          Authorization: jwtToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ upgradeOption: selectedUpgrade }),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        Swal.fire({
          icon: 'error',
          text: errorResponse.error || 'An error occurred while upgrading your account. Please try again later.',
        });

        return;
      }

      const successResponse = await response.json();

      // Show success message from the backend response using Swal.fire
      Swal.fire({
        icon: 'success',
        title: 'Upgrade Successful',
        text: successResponse.message || 'Your account has been successfully upgraded!',
      });

      // Optionally, you can refresh the page
      window.location.reload();
    } catch (error) {
    }
  }
};


	
	
  const handleGenerateFundingAccount = async () => {
try {
  const response = await fetch(`${BASE_URL}/api/new_autofundin_account`, {
method: 'POST',
headers: {
  Authorization: jwtToken,
},
  });

  if (!response.ok) {
const errorResponse = await response.json();
console.error('Error generating funding account:', errorResponse);
return;
  } 

  // Refresh the page after successful generation
  window.location.reload();
} catch (error) {
  console.error('Error generating funding account:', error);
}
  };

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [fundingData, setFundingData] = useState([]);
  const [userData, setUserData] = useState({});
  const [dataPlan, setDataPlan] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [levelPlan, setLevelPlan] = useState([]);
  const [autoFundingStatus, setAutoFundingStatus] = useState('');

  const isUserLoggedIn = useAuthentication();
  const jwtToken = Cookies.get('authorization');

  
  const SITE_WHATS = process.env.SITE_WHATSAPP;

  useEffect(() => {
if (isUserLoggedIn) {
  fetchUserData();
  fetchLevelPlan();
  fetchAutoFundingStatus();
}
  }, [isUserLoggedIn]);

  const fetchUserData = async () => {
try {
  const response = await fetch(`${BASE_URL}/api/user`, {
method: 'GET',
headers: {
  Authorization: jwtToken,
},
  });

  if (!response.ok) {
const errorResponse = await response.json();
return;
  }

  const data = await response.json();
  setUserData(data.userInformation);
  setFundingData(data.funding);
  setDataPlan(data.data_plan);
  setNetworks(data.networks);
  
  
} catch (error) {
  console.error('Error fetching user data:', error);
}
  };


 const fetchAutoFundingStatus = async () => {
  try {
const response = await fetch(`${BASE_URL}/status`);

if (!response.ok) {
  const errorResponse = await response.json();
  console.error('Error fetching auto funding status:', errorResponse);
  return;
}

const statusData = await response.json();
setAutoFundingStatus(statusData.services.funding === "0" ? statusData.services.bank_user : '');

if (statusData.services.notifications !== "") {

Swal.fire({
  html: statusData.services.notifications.replace(/\n/g, '<br>'),
  showCancelButton: true,
  showCloseButton: true,
  cancelButtonText: 'Info Group',
  cancelButtonAriaLabel: 'Info Group',
    confirmButtonText: 'Close',
  focusCancel: true,
  showDenyButton: true,
  denyButtonText: 'Support',
  denyAriaLabel: 'Support',
}).then((result) => {
  if (result.isConfirmed) {

  } else if (result.isDenied) {
    window.open(`https://wa.me/${WhatsApp}`, '_blank');
  } else if (result.dismiss === Swal.DismissReason.cancel) {
    window.open(`${WhatsApp_Group}`, '_blank');
  }
});

  
  
  
  
}
  } catch (error) {
console.error('Error fetching auto funding status:', error);
  }
};


//  alert (userData.notification_count);
const history = useHistory();


if (userData.notification_count > 0) {
  Swal.fire({
    text: `You have ${userData.notification_count} unread notifications.`,
    icon: 'info',
    confirmButtonText: 'OK',
  }).then((result) => {
    if (result.isConfirmed) {
 history.push("notifications");
    }
  });
}


  const fetchLevelPlan = async () => {
try {
  const response = await fetch(`${BASE_URL}/api/level_plan`);

  if (!response.ok) {
const errorResponse = await response.json();
return;
  }

  const levelPlanData = await response.json();
  setLevelPlan(levelPlanData);
} catch (error) {
  console.error('Error fetching level plan data:', error);
}
  };

  const getPriceFor1GB = (networkCode) => {
const plan = dataPlan.find(
  (plan) =>
plan.network === Number(networkCode) && plan.data_size === "1GB"
);
return plan ? plan.amount : 0;
  };

  const getNetworkAvatar = (networkName) => {
switch (networkName) {
  case "MTN":
return Mtn;
  case "Glo":
return GLo;
  case "9mobil":
return Etisalat;
  case "Airtel":
return Airtel;
  default:
return null;
}
  };

const mymodal = (text) => {
  Swal.fire({
    html: `You currently have ${userData.bonus} Naira. You can move your bonus to your wallet at any time`,
    showCancelButton: false,
    showCloseButton: true,
    confirmButtonText: 'Copy Link',
    cancelButtonAriaLabel: 'Move to Wallet',
    focusCancel: false,
    showDenyButton: true,
    denyButtonText: 'Move to Wallet',
	
  }).then(async (result) => {
    if (result.isConfirmed) {
  
  
  
 
 
 
 
 
 Swal.fire({
  text: `Invite and receive a lifetime reward of ${REF_PERC}% on all wallet top-ups made by those you invited to this platform!`,
  input: "text",
  inputAttributes: {
    autocapitalize: "off"
  },
  showCancelButton: true,
  confirmButtonText: "Copy",
  showLoaderOnConfirm: false,
  didOpen: () => {
  
    Swal.getInput().value = `https://cart9.com.ng?invite=${userData.ref_code}`;
    Swal.getInput().readOnly = true;
  },
  preConfirm: async (login) => {
    try {
     handleCopy(`https://cart9.com.ng?invite=${userData.ref_code}`)
    } catch (error) {
      Swal.showValidationMessage(`
        Request failed: ${error}
      `);
    }
  },
  allowOutsideClick: () => !Swal.isLoading()
}).then((result) => {

});

 
 
 
 
 
 
 
 
 
 
 
  
  
  
  
    } else if (result.isDenied) {
      // Send POST request to move bonus to wallet
      try {
        const response = await fetch(`${BASE_URL}/api/movebonus`, {
          method: 'POST',
          headers: {
            Authorization: jwtToken,
          },
        });

        if (response.ok) {
          // Success case
          Swal.fire({
            icon: 'success',
            title: 'Wallet credited successfully!',
            showConfirmButton: false,
            timer: 1500,
          });

          window.location.reload();
        } else {
          // Error case
          const errorResponse = await response.json();
          Swal.fire({
            icon: 'error',
            text: errorResponse.error,
          });
        }
      } catch (error) {
        // Network or unexpected error
        console.error('Error moving bonus:', error);

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred. Please try again.',
        });
      }
    }
  });
};


const handleCopy = (text) => {
  navigator.clipboard.writeText(text);
  Swal.fire({
text: 'Copied to clipboard',
timer: 1100, // Auto-close after 2 seconds
timerProgressBar: true,
showConfirmButton: false,
  });
};




  return (
<Box pt={{ base: "40px", md: "80px", xl: "80px" }}>

<center><b>Free Advice:</b>  {advice}</center><br />

  <SimpleGrid columns={{ base: 1, md: 2, lg: 2, }} gap='1px' mb='5px'>


<a onClick={() => mymodal()}>
<MiniStatistics startContent={
<IconBox
  icon={
<Icon w='32px' h='32px' as={MdOutlineVerifiedUser} color={brandColor} />
  }
/>
  }
  name='User Level'
  value={userData.level}
/></a>






<a onClick={() => mymodal()}>
<MiniStatistics startContent={
<IconBox
  icon={
<Icon w='32px' h='32px' as={MdGroup} color={brandColor} />
  }
/>
  }
  name='Referral Bonus'
  value={`₦${userData.bonus}`}
/></a>

  </SimpleGrid>



  {autoFundingStatus ? (
<Box p='20px' align='center' direction='column' w='100%' bg="white" borderRadius="10px">
  {autoFundingStatus}
</Box>
  ) : (
<>



<b> Quick Link </b><br />

<SimpleGrid columns={{ base: 3 }} align='center' direction='column' gap='4px' w='100%' >

<a href='#/admin/nft-marketplace'>
<Box p='5px' align='center' direction='column' w='100%' bg="#4318FF" color="#fff" borderRadius="10px">
<MdWifi size={26} color={brandColor} /> Data
</Box></a>





<a href='#/admin/electric'>
<Box p='5px' align='center' direction='column' w='100%' bg="#4318FF" color="#fff" borderRadius="10px">
<MdPower size={26} color={brandColor} /> Light
</Box></a>




<a href='#/admin/summary'>
<Box p='5px' align='center' direction='column' w='100%' bg="#4318FF" color="#fff" borderRadius="10px">
<MdInfo size={26} color={brandColor} /> Orders
</Box></a>






</SimpleGrid>



<SimpleGrid columns={{ base: 1 }} p='15px' align='center' direction='column' w='100%' bg="white" borderRadius="3px">
Automatic wallet funding account<hr /><br />
  {fundingData.map((bankAccount, index) => (
<p key={index}><b>
  Bank:{bankAccount.bank},<br />
  Name: {bankAccount.account_name}<br />ACN: <span className="copyable">{bankAccount.account_number}</span></b><br />
  
<button className="copy-button" color="#4318FF" onClick={() => handleCopy(bankAccount.account_number)}>CLICK HERE TO COPY ACN</button><br />
  
  <br />
<i>Note: A 1.5% bank charge will be applied.</i>
</p>
  ))}
</SimpleGrid><br />

</>
  )}


<br />



{userData.level !== "reseller" && (
  <SimpleGrid columns={1} p='20px' align='center' direction='column' w='100%' bg="white" borderRadius="10px">
<Box mb="20px">
  <Text fontSize="xl" fontWeight="bold">Available upgrade options</Text>
  <hr />
</Box>
{userData.level === "enduser" && (
  <SimpleGrid columns={1} gap='20px'>
{levelPlan
  .filter((plan) => plan.size === 1 && plan.size_type === "GB")
  .sort((plan1, plan2) => plan1.network - plan2.network)
  .map((plan, index) => {
let main_network_name;
switch (plan.network) {
case 1:
  main_network_name = "MTN";
  break;
case 2:
  main_network_name = "Glo";
  break;
case 3:
  main_network_name = "Etisalat";
  break;
case 4:
  main_network_name = "Airtel";
  break;
default:
  main_network_name = "Unknown";
}

return (
<Flex key={index} align="center" justify="space-between" p="10px" borderRadius="10px" bg="gray.100">
  <Flex direction="column">
<Text fontSize="lg">{plan.plan_type.replace(/_/g, ' ')}</Text>

<Text fontSize="sm">Amount per {plan.size} {plan.size_type}</Text>
  </Flex>
  <Flex direction="column" align="flex-end">
<Text fontSize="sm">Enduser: ₦{plan.enduser_amnt}</Text>
<Text fontSize="sm">Agent: ₦{plan.agent_amnt}</Text>
<Text fontSize="sm">Reseller: ₦{plan.reseller_amnt}</Text>
  </Flex>
</Flex>
);
  })} 
  
  
    <hr />
	
	
  
  <form style={{ display: 'block', textAlign: 'center', marginBottom: '20px' }}>
  <select value={selectedUpgrade} onChange={(e) => setSelectedUpgrade(e.target.value)}>
    <option value="">Click to Select Upgrade Plan</option>
    <option value="agent_upgrade">Upgrade to Agent - ₦1,500</option>
    <option value="reseller_upgrade">Upgrade to Reseller/API - ₦3,000</option>
  </select>
  <button
    type="button"
    onClick={handleUpgradeSubmit}
    style={{
 background: 'red',
 color: 'white',
 padding: '10px 20px',
 border: 'none',
 borderRadius: '5px',
 cursor: 'pointer',
    }}
  >
    Upgrade
  </button>
</form><hr />
<i>If you're interested in having a VTU website similar to this one through our API, the expense for the upgraded version including website installation would be ₦60,000</i><br /><hr />
  
  
  
  </SimpleGrid>
)}
  </SimpleGrid>
)}

<br />






  <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>


  <Tasks />

  <Tasks2 />

</SimpleGrid>
 

  </SimpleGrid>

<MiniCalendar h='100%' W='100%' selectRange={true} />

</Box>
  );
}

