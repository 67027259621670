// Frontend
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, useColorModeValue, Table, Tbody, Tr, Td, Button } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function OrderStatus() {
  const boxBg = useColorModeValue('white', 'secondaryGray.300');

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const jwtToken = Cookies.get('authorization');
  const location = useLocation();
  const id = location.pathname.split('/').pop();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
const fetchOrderStatus = async () => {
try {
  const response = await axios.get(`${BASE_URL}/api/order_status/${id}`, {
headers: {
Authorization: jwtToken,
},
  });

  if (response.status !== 200) {
console.error('Error fetching order status:', response.data.error);
setOrderData({ status: 500, error: 'Invalid Data Plan' });
return;
  }

  setOrderData(response.data); // Access the response data directly

} catch (error) {
  console.error('Error fetching order status:', error);
  setOrderData({ status: 500, error: 'Invalid Data Plan' });
}
};

if (jwtToken) {
setIsUserLoggedIn(true);
fetchOrderStatus();
} else {
setIsUserLoggedIn(false);
}
  }, [id, jwtToken]);

  return (
<Box pt={{ base: '40px', md: '80px', xl: '80px' }}>
<div>
  {orderData && orderData.status === 200 && orderData.receipt ? (
<Table variant="striped" bg="#D0D5C9">
<Tbody>

  <Tr>
  <Td>Order Id: {orderData.receipt.order_id}</Td>
<Td>Service: {orderData.receipt.service}</Td>
  </Tr>
  
   <Tr>
  <Td>Recipient {orderData.receipt.number}</Td>
<Td>Status: {orderData.receipt.status}</Td>
  </Tr>
  
  
  
  <Tr>
<Td>Old Balance: ₦{orderData.receipt.old_bal}</Td>
<Td>Order Amount: ₦{orderData.receipt.amount_sold}</Td>

  </Tr>
  

  
  <Tr>
<Td>New balance: ₦{orderData.receipt.new_bal}</Td>
<Td>Date: {orderData.receipt.date}</Td>
</Tr>

  
  <Tr>
<Td colSpan="2">#{orderData.receipt.rand}</Td>
</Tr>
</Tbody>
</Table>
  ) : (
<p>{orderData?.error || 'Loading...'}</p>
  )}
  <br />
  <center> <Button as={Link} to="/admin/default" colorScheme="teal">
            Home
          </Button>
</center>
</div>
</Box>
  );
}
